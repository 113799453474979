import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2666c510"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("li", {
    class: "image-holder",
    style: _normalizeStyle(_ctx.dimensions)
  }, [
    _createVNode(_component_router_link, {
      to: { name: 'detail', params: { filename: _ctx.image.name } }
    }, {
      default: _withCtx(() => [
        _createElementVNode("img", {
          src: '/media/' + _ctx.image.name + '_thumb.jpg',
          loading: "lazy"
        }, null, 8, _hoisted_1)
      ]),
      _: 1
    }, 8, ["to"])
  ], 4))
}