
import { defineComponent, PropType } from "vue";
import Detail from "@/components/Detail.vue";
import Modal from "@/components/Modal.vue";
import GridImage from "@/components/GridImage.vue";
import { Photo } from "@/models/Photo";

export default defineComponent({
  name: "Grid",
  props: {
    images: Array as PropType<Array<Photo>>,
  },
  components: {
    Detail,
    Modal,
    GridImage,
  },
  methods: {
    dismiss() {
      this.$router.push({ name: "home" });
      this.showModal = false;
    },
    listener(event: KeyboardEvent) {
      if (this.showModal && event.code.toLocaleLowerCase() === "escape") {
        this.dismiss();
      }
    },
  },
  watch: {
    $route(newVal) {
      this.showModal = newVal.meta && newVal.meta.showModal;
    },
  },
  data() {
    return {
      showModal: this.$route.meta.showModal,
    };
  },
  mounted() {
    document.addEventListener("keydown", this.listener);
  },
  unmounted() {
    document.removeEventListener("keydown", this.listener);
  },
});
