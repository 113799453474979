import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4fb39575"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "not-found" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_router_link, {
      class: "copy",
      to: { name: 'home' }
    }, {
      default: _withCtx(() => [
        _createTextVNode(" There Is Nothing Here ")
      ]),
      _: 1
    })
  ]))
}