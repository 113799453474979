import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GridImage = _resolveComponent("GridImage")!
  const _component_Detail = _resolveComponent("Detail")!
  const _component_Modal = _resolveComponent("Modal")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("ul", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.images, (image) => {
        return (_openBlock(), _createBlock(_component_GridImage, {
          key: image.filename,
          image: image
        }, null, 8, ["image"]))
      }), 128))
    ]),
    _createVNode(_component_router_view, null, {
      default: _withCtx(() => [
        (_ctx.showModal)
          ? (_openBlock(), _createBlock(_component_Modal, {
              key: 0,
              onClick: _ctx.dismiss
            }, {
              default: _withCtx(() => [
                _createVNode(_component_Detail)
              ]),
              _: 1
            }, 8, ["onClick"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}