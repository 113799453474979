<template>
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512.000000 512.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <metadata>
      Created by potrace 1.16, written by Peter Selinger 2001-2019
    </metadata>
    <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)">
      <path
        d="M1219 4777 c-22 -19 -25 -28 -21 -64 3 -33 8 -43 22 -43 14 0 23 14
34 54 l16 54 78 -4 c42 -2 114 -6 160 -8 45 -2 82 0 82 5 0 22 -49 29 -192 29
-143 -1 -155 -2 -179 -23z"
      />
      <path
        d="M1275 4747 c-8 -12 -15 -33 -15 -48 l0 -26 177 -7 c109 -4 180 -3
186 3 16 16 -1 57 -28 69 -14 7 -83 14 -153 17 -70 4 -133 8 -139 10 -7 2 -19
-6 -28 -18z"
      />
      <path
        d="M1165 4730 c-4 -6 -5 -27 -3 -48 3 -34 1 -37 -26 -42 -22 -4 -32 -13
-42 -40 -7 -19 -13 -43 -13 -53 -1 -10 -14 -30 -31 -44 -29 -24 -30 -27 -30
-113 l0 -87 -61 -13 c-78 -17 -135 -57 -148 -107 -6 -21 -11 -42 -11 -48 0 -5
-24 -19 -53 -30 -44 -17 -55 -27 -75 -67 -35 -70 -44 -199 -36 -548 6 -294 -1
-461 -35 -800 l-6 -65 -125 -28 c-69 -15 -145 -38 -170 -50 l-45 -22 -9 -90
c-5 -54 -4 -215 3 -400 15 -427 14 -743 -5 -1028 -18 -272 -15 -371 14 -437
30 -69 49 -81 144 -91 48 -6 987 -8 2243 -7 2093 3 2161 4 2191 22 l31 19 6
126 c4 69 11 200 17 291 12 213 16 604 11 1153 -4 389 -6 439 -20 446 -42 22
-70 22 -1471 21 -377 0 -1102 3 -1612 6 -913 7 -928 8 -928 27 0 19 5 19 188
12 270 -10 1272 -7 1652 6 290 10 583 7 710 -7 25 -3 63 -7 85 -9 113 -13 487
-16 630 -7 250 17 281 33 408 204 l57 78 78 -1 c90 -1 127 13 149 55 21 41 35
315 31 616 -3 215 -5 238 -23 270 -29 49 -75 69 -167 71 l-76 2 -67 74 c-37
41 -85 101 -107 134 -44 66 -71 89 -105 89 -18 0 -23 6 -23 24 0 35 -20 46
-95 53 l-68 6 6 80 c6 86 -3 127 -28 127 -11 0 -15 11 -15 39 0 21 -5 52 -11
69 l-10 30 -167 7 c-217 9 -401 12 -882 16 -217 2 -337 5 -267 6 l128 4 -3 27
-3 27 -210 7 -210 7 -27 -31 -26 -31 -601 5 c-331 3 -605 8 -608 12 -4 4 -7
25 -8 46 0 39 -8 50 -20 30z m683 -235 c178 -2 326 -6 329 -9 3 -3 6 -45 7
-93 l1 -88 -305 -3 c-366 -4 -670 6 -670 21 0 6 13 38 29 71 17 34 35 74 41
90 l10 29 118 -7 c64 -4 263 -9 440 -11z m-654 -11 c10 -25 7 -75 -7 -129
l-13 -50 -60 0 -59 0 0 85 c0 80 1 85 25 97 37 19 106 17 114 -3z m1708 -4
c86 0 89 -6 86 -163 l-3 -131 -182 -4 -183 -4 0 134 c0 182 0 183 129 174 53
-3 122 -6 153 -6z m1192 -54 c3 -12 6 -55 6 -96 l0 -76 -342 4 c-189 2 -385 0
-435 -5 l-93 -9 0 93 0 93 118 0 c126 0 646 12 649 14 2 1 23 2 47 2 37 1 46
-3 50 -20z m-1913 -163 c1 -16 2 -41 3 -58 l1 -30 -515 -7 c-428 -6 -635 -14
-795 -31 -17 -2 -20 4 -18 31 2 44 26 69 87 89 45 15 114 17 628 18 318 1 581
4 584 8 15 14 24 6 25 -20z m2039 -41 c19 -4 36 -15 38 -23 2 -13 -56 -15
-510 -13 l-513 2 390 -8 c368 -7 516 -13 628 -26 49 -6 97 -37 118 -77 11 -20
11 -20 -19 1 -16 12 -56 29 -88 39 -53 15 -125 17 -704 18 -529 0 -651 -2
-680 -13 -29 -12 -99 -13 -420 -6 -448 10 -426 10 -860 2 -365 -7 -477 -15
-563 -37 -22 -5 -60 -7 -86 -5 l-47 4 -31 -52 -32 -53 1 -685 c1 -377 -2 -695
-6 -708 -6 -18 -14 -22 -34 -20 l-27 3 3 165 c2 91 8 390 12 665 9 530 13 574
60 637 11 15 20 30 20 33 0 3 -18 5 -39 5 -62 0 -57 17 7 23 31 3 91 10 132
15 152 20 725 44 840 36 36 -2 283 -5 550 -7 478 -2 650 5 650 27 0 7 4 6 8
-2 6 -9 35 -12 108 -10 l99 3 3 32 c3 35 10 38 112 47 75 7 843 -4 880 -12z
m-2739 -233 c-17 -22 -37 -61 -45 -87 -38 -118 -48 -398 -28 -762 12 -202 30
-312 68 -399 l24 -56 -25 30 c-35 42 -62 130 -82 265 -22 149 -25 716 -5 825
15 78 52 168 84 202 35 37 40 26 9 -18z m2234 26 c-153 -28 -207 -55 -294
-151 -55 -60 -92 -131 -141 -274 -32 -91 -34 -101 -33 -240 0 -237 42 -427
114 -523 44 -58 120 -124 166 -143 43 -19 63 -18 35 2 -25 17 -29 34 -8 34 7
0 38 -11 69 -25 34 -16 85 -28 138 -34 126 -13 387 -13 464 -1 81 13 196 64
248 109 46 41 47 29 5 -35 -45 -66 -111 -109 -190 -122 -190 -32 -525 -23
-688 18 -41 10 -175 37 -296 59 -420 76 -482 95 -562 171 -42 40 -57 70 -81
164 -60 230 -45 553 34 720 28 59 142 189 184 210 18 9 98 15 266 20 137 3
276 12 325 21 144 25 203 33 260 33 l55 0 -70 -13z m544 -15 c92 -22 151 -47
151 -62 0 -6 -8 -7 -17 -3 -68 26 -229 48 -358 48 -251 1 -379 -46 -509 -184
-159 -169 -204 -286 -223 -580 -6 -104 -8 -110 -16 -69 -5 25 -10 119 -10 210
-2 159 -1 168 27 245 49 138 89 207 155 267 79 73 140 102 286 133 107 22 137
24 275 21 112 -4 178 -11 239 -26z m61 -75 c172 -36 202 -52 260 -145 73 -116
83 -161 94 -400 7 -157 -30 -350 -85 -447 -37 -64 -105 -140 -157 -177 -22
-15 -73 -39 -113 -54 -73 -26 -79 -26 -319 -26 -200 0 -256 3 -307 17 -147 41
-201 80 -283 204 -71 107 -85 194 -69 427 11 151 29 223 82 315 101 178 255
280 467 310 150 22 231 17 430 -24z m391 -90 c68 -7 86 -24 106 -97 18 -68 30
-507 15 -558 -8 -27 -10 11 -11 162 -1 300 -15 432 -48 460 -9 7 -50 15 -91
18 -51 3 -77 9 -80 19 -3 10 3 12 24 8 16 -3 54 -9 85 -12z m-2682 -85 c36
-19 73 -78 78 -125 6 -49 -18 -138 -45 -164 -15 -15 -15 -14 -8 4 38 93 27
164 -34 219 -64 58 -142 60 -205 5 -39 -34 -62 -88 -74 -174 l-9 -60 -1 78
c-1 53 4 90 14 111 22 46 76 95 121 111 43 14 132 12 163 -5z m-50 -75 c38
-33 54 -83 49 -153 -7 -75 -36 -102 -114 -102 -87 0 -109 35 -102 158 5 96 24
122 87 122 38 0 56 -6 80 -25z m-1336 -1094 c92 -41 115 -42 2152 -47 1078 -2
1968 -4 1978 -4 13 0 17 -8 17 -34 0 -32 -2 -34 -42 -39 -209 -29 -1118 -41
-2928 -41 -939 0 -1396 11 -1452 33 -23 8 -28 17 -28 43 0 18 4 39 9 46 7 12
186 58 234 61 10 1 37 -8 60 -18z m4078 -363 c-1 -95 -3 -288 -5 -428 -3 -140
-5 -279 -5 -307 -1 -29 -5 -53 -9 -53 -4 0 -6 105 -5 233 5 363 15 739 20 733
3 -2 4 -82 4 -178z m-1673 147 c11 -10 -1481 -7 -1510 4 -11 4 322 6 742 4
420 -1 765 -5 768 -8z m945 -41 c171 -35 264 -81 362 -179 50 -50 81 -92 107
-145 71 -144 117 -361 104 -484 -6 -54 -37 -191 -51 -228 -4 -10 13 -12 87 -9
51 1 90 0 86 -4 -4 -5 -25 -9 -45 -10 -21 -1 -48 -3 -60 -4 -30 -2 -51 -26
-108 -128 -87 -154 -270 -324 -386 -357 -30 -8 -121 -25 -203 -37 -187 -27
-410 -23 -521 8 -88 24 -216 91 -277 143 -97 83 -224 276 -269 406 -31 89 -31
90 -24 239 8 192 27 297 72 410 67 167 141 250 284 318 52 25 121 50 153 56
33 6 159 11 280 11 219 1 236 -1 476 -46 16 -3 12 1 -10 9 -19 8 -64 19 -100
26 -36 6 -85 16 -110 21 -40 8 -38 9 20 4 36 -3 96 -12 133 -20z m-2668 -73
c61 -27 90 -53 118 -106 21 -38 22 -53 22 -297 0 -252 0 -257 -22 -280 -13
-14 -23 -29 -23 -34 0 -16 -83 -92 -114 -104 -16 -6 -47 -12 -70 -15 -37 -3
-34 -1 21 16 49 15 74 31 113 71 60 61 68 85 9 28 -22 -22 -66 -52 -97 -67
-48 -23 -70 -28 -135 -28 -73 0 -80 2 -102 27 -35 41 -48 124 -48 303 0 119 5
192 22 285 31 178 30 176 53 206 21 26 23 26 112 22 66 -3 105 -10 141 -27z
m-330 -168 c-9 -70 -19 -226 -22 -345 -4 -145 -9 -215 -16 -211 -13 8 -1 528
13 593 13 54 31 102 37 96 3 -2 -3 -62 -12 -133z m3865 -1117 c-11 -183 -20
-361 -20 -394 0 -54 -3 -62 -22 -69 -26 -9 -4320 -7 -4415 2 -54 6 -66 11 -91
40 -35 39 -54 112 -56 209 -1 135 7 311 20 406 l13 95 -7 -120 c-4 -66 -5
-203 -3 -304 4 -254 20 -287 141 -310 36 -6 546 -11 1450 -11 767 -1 1548 -3
1735 -3 297 -2 1046 11 1130 18 17 2 37 3 46 4 9 1 20 9 24 18 4 10 14 124 21
253 8 129 19 271 24 315 6 44 10 123 10 176 0 54 3 106 8 116 15 37 13 -115
-8 -441z m-2163 428 c16 -3 24 -14 29 -40 3 -19 3 -39 -2 -44 -7 -6 -711 -17
-1514 -23 -204 -2 -863 22 -873 32 -4 4 -7 21 -5 37 l3 29 125 8 c69 4 285 5
480 2 431 -8 525 -8 750 3 169 9 962 5 1007 -4z m2143 -29 c0 -38 -3 -45 -22
-50 -29 -6 -271 -25 -275 -21 -2 1 4 24 13 49 19 57 37 63 197 66 l87 1 0 -45z
m-122 -412 c-8 -335 -12 -389 -28 -362 -8 12 -79 14 -485 13 -261 -1 -475 2
-475 6 0 12 205 16 603 14 l357 -3 2 27 c0 15 2 41 4 57 2 25 15 552 14 578 0
5 3 6 8 4 4 -3 4 -153 0 -334z m-2208 -353 c-107 -3 -481 -5 -830 -6 -349 0
-545 2 -435 5 110 3 484 6 830 6 347 1 542 -2 435 -5z"
      />
      <path
        d="M1957 4460 c-4 -14 -7 -48 -7 -77 l0 -52 33 1 c60 0 67 5 67 54 0 44
0 44 35 44 33 0 35 -2 35 -35 0 -22 -6 -38 -17 -44 -14 -8 -10 -10 23 -11 l40
0 -2 43 c-3 84 6 77 -98 77 -66 0 -96 4 -98 12 -3 7 -7 1 -11 -12z"
      />
      <path
        d="M2661 4456 c-11 -13 -18 -243 -8 -253 3 -3 73 -2 155 2 l150 7 5 27
c10 48 -1 193 -15 206 -20 19 -273 29 -287 11z"
      />
      <path
        d="M3243 4374 c-3 -9 -2 -33 3 -53 l9 -36 74 -3 c41 -2 79 -1 85 1 7 3
10 25 8 56 l-4 51 -84 0 c-69 0 -86 -3 -91 -16z"
      />
      <path
        d="M3935 3839 c-84 -20 -171 -51 -213 -76 -56 -32 -174 -147 -196 -191
-25 -48 -46 -320 -35 -442 11 -122 15 -137 40 -151 11 -6 38 -26 59 -45 50
-46 136 -84 223 -98 146 -26 361 3 471 63 59 31 147 109 175 155 28 45 61 244
61 366 0 149 -19 190 -153 328 -27 27 -149 79 -218 92 -63 12 -160 11 -214 -1z
m250 -255 c75 -35 87 -147 29 -262 -39 -79 -73 -112 -115 -112 -49 0 -84 41
-113 134 -28 91 -32 131 -15 189 8 29 18 42 42 52 43 19 132 19 172 -1z"
      />
      <path
        d="M1864 3649 c-26 -28 -26 -144 -1 -183 23 -34 69 -35 102 -1 21 20 25
33 25 87 0 87 -15 112 -66 116 -31 2 -45 -2 -60 -19z"
      />
      <path
        d="M3655 2265 c-99 -19 -154 -39 -288 -107 -198 -101 -296 -219 -348
-417 -24 -88 -28 -247 -10 -326 36 -153 125 -325 217 -417 96 -98 254 -169
450 -203 108 -19 172 -19 266 -1 247 49 401 173 519 420 48 100 64 215 55 401
-12 254 -90 425 -252 554 -71 56 -128 82 -213 97 -95 17 -303 16 -396 -1z
m359 -19 c105 -21 179 -60 264 -140 108 -103 156 -211 183 -415 14 -106 6
-305 -15 -386 -28 -105 -93 -220 -172 -304 -82 -87 -131 -117 -259 -157 -75
-24 -107 -28 -190 -28 -152 2 -232 23 -334 91 -160 105 -310 276 -362 412 -30
78 -37 233 -15 321 21 82 66 178 112 241 41 57 163 141 280 195 174 81 428 86
589 13 28 -13 55 -25 60 -27 6 -3 -1 7 -14 22 -45 48 -121 69 -273 74 -270 9
-484 -69 -632 -228 -159 -172 -213 -454 -124 -649 52 -116 217 -298 351 -388
20 -14 36 -28 33 -30 -6 -7 -124 55 -187 97 -91 63 -202 230 -255 385 -68 203
-7 502 135 658 79 85 320 207 481 241 86 19 250 19 344 2z"
      />
      <path
        d="M3652 2015 c-143 -40 -309 -215 -342 -360 -6 -27 -15 -57 -20 -66
-15 -29 -4 -190 18 -251 66 -183 242 -313 464 -343 118 -16 269 46 363 147 62
67 137 210 154 296 39 189 -49 399 -209 502 -120 77 -305 110 -428 75z m-13
-101 c-7 -9 -39 -34 -71 -55 -32 -22 -63 -49 -69 -61 -8 -15 3 -9 37 19 71 58
113 81 185 99 173 44 360 -45 435 -209 36 -80 45 -200 19 -280 -46 -143 -131
-269 -208 -308 -35 -18 -54 -21 -151 -17 -107 3 -114 5 -186 43 -91 48 -169
124 -205 201 -36 76 -46 221 -21 307 9 31 15 58 14 59 -5 5 55 102 77 126 21
23 131 90 149 91 5 1 2 -6 -5 -15z m626 -441 c-24 -138 -86 -266 -159 -330
-71 -62 -246 -129 -246 -94 0 6 4 11 10 11 36 0 128 48 177 93 82 75 185 239
207 330 10 41 19 33 11 -10z"
      />
      <path
        d="M3705 1877 c-100 -33 -196 -106 -240 -181 -34 -58 -50 -178 -35 -254
24 -117 102 -227 192 -271 150 -72 323 -56 397 38 35 44 91 155 117 231 27 79
25 200 -3 262 -28 61 -95 125 -163 156 -66 31 -200 40 -265 19z"
      />
      <path
        d="M1102 2200 c-38 -41 -65 -223 -64 -435 1 -188 11 -236 54 -268 35
-26 102 -24 169 4 51 22 110 84 145 152 22 41 36 300 22 395 -20 135 -66 172
-215 172 -80 0 -95 -3 -111 -20z"
      />
      <path
        d="M3790 4693 c-27 -5 -40 -19 -40 -44 0 -14 20 -18 129 -24 72 -4 139
-4 150 0 26 8 27 29 1 55 -17 17 -32 20 -117 19 -54 -1 -109 -4 -123 -6z"
      />
    </g>
  </svg>
</template>

<script setup lang="ts"></script>

<style scoped></style>
