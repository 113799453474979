
import { defineComponent } from "vue";
import Grid from "@/components/Grid.vue";
import CameraIcon from "@/components/CameraIcon.vue";
import config from "@/assets/website-config.json";

export default defineComponent({
  inject: ["store"],
  name: "Home",
  components: {
    Grid,
    CameraIcon,
  },
  computed: {
    name() {
      return config.author.name;
    },
    twitter() {
      return config.author.twitter;
    },
    year() {
      return new Date().getFullYear();
    },
  },
});
