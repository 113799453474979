
import { defineComponent, PropType } from "@vue/runtime-core";
import { Photo } from "@/models/Photo";

export default defineComponent({
  name: "GridImage",
  props: {
    image: Object as PropType<Photo>,
  },
  computed: {
    dimensions() {
      return {
        "--image-aspect-ratio":
          this.image?.sizes.thumb.width +
          " / " +
          this.image?.sizes.thumb.height,
        "--image-width": this.image?.sizes.thumb.width + "px",
        "--image-height": this.image?.sizes.thumb.height + "px",
      };
    },
  },
});
