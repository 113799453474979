
import { defineComponent, onMounted } from "vue";
import store from "@/store";

export default defineComponent({
  provide() {
    return {
      store: store,
    };
  },
  name: "App",
  components: {},
  setup() {
    onMounted(async () => {
      store.loadPhotos();
    });
  },
});
